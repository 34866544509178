import axios from "axios";
import URLS from "../urls";

export const getBInsList =  async () =>{
    const url =  URLS.GET_BINS_LIST;
    // const url = 'http://localhost:3000/prod/analytics/get_bins_list';
    return await axios.get(url).then((response) => response.data);
}

export const getAvgFillLevel =  async (payload) =>{
    const url =  URLS.GET_AVG_FILL_LEVEL; 
    // const url = 'http://localhost:3000/prod/analytics/get_avg_fill_level';
    return await axios.post(url , payload).then((response) => response.data);
}

export const getBInsOverview =  async () =>{
    const url =  URLS.GET_BINS_OVERVIEW;
    return await axios.get(url).then((response) => response.data.data);
}

export const getPickUpChart =  async (payload) =>{
    const url =  URLS.GET_PICK_UP;
    // const url = 'http://localhost:3000/prod/analytics/get_pick_up';
    return await axios.post(url , payload).then((response) => response.data);
}


export const getBinVolume =  async (payload) =>{
    const url =  URLS.GET_BIN_VOLUME; 
    // const url = 'http://localhost:3000/prod/analytics/get_bin_volume';
    return await axios.post(url , payload).then((response) => response.data);
}

export const getBuildingVolume =  async (payload) =>{
    const url =  URLS.GET_BUILDING_VOLUME;
    // const url = 'http://localhost:3000/prod/analytics/get_building_volume';
    return await axios.post(url , payload).then((response) => response.data);
}

export const getBinsWTOverview =  async () =>{
    const url =  URLS.GET_BINS_WT_OVERVIEW;
    // const url = 'http://localhost:3000/prod/analytics/get_bins_overview';
    return await axios.get(url).then((response) => response.data);
}