
import { defineComponent, onMounted, ref } from "vue";
//import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getBInsOverview } from "@/store/api/KpiServices";

export default defineComponent({
  name: "widget-5",
  components: {
    //Dropdown3,
  },
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    btnColor: String,
  },
  setup(props) {
    const color = ref(props.chartColor);
    const totalBins = ref<any>();

    const label = ref<string[]>([
      "Full Bins",
      "OverFlowing",
      "Garbage Around",
      "Offline",
    ]);
    const series = ref<number[]>([]);
    const response = ref<any>({});

    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    // const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
    // const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    onMounted(() => {
      getBInsOverview().then((res) => {
        series.value.push(res.full.total);
        series.value.push(res.overflowing.total);
        series.value.push(res.garbage_around.total);
        // series.value.push(res.active.total);
        series.value.push(res.offline.total);
        response.value = res;
        totalBins.value = res.offline.total + res.active.total;
      });
    });



    // const series = [880, 200, 300];

    return {
      series,
      response,
      totalBins,
    };
  },
});
