const base_url = process.env.VUE_APP_API_URL;

const GET_BINS_LIST = base_url + "analytics/get_bins_list";

const GET_AVG_FILL_LEVEL = base_url + "analytics/get_avg_fill_level";
const GET_BINS_OVERVIEW = base_url + "bins/overview";
const GET_PICK_UP = base_url + "analytics/get_pick_up";

const GET_BIN_VOLUME = base_url + "analytics/get_bin_volume";

const GET_BUILDING_VOLUME = base_url + "analytics/get_building_volume";

const GET_BINS_WT_OVERVIEW = base_url + "analytics/get_bins_overview"

const URLS = {
    GET_BINS_LIST,
    GET_AVG_FILL_LEVEL,
    GET_BIN_VOLUME,
    GET_BINS_OVERVIEW,
    GET_PICK_UP,
    GET_BUILDING_VOLUME,
    GET_BINS_WT_OVERVIEW
}

export default URLS;