
import { defineComponent } from "vue";

export default defineComponent({
  name: "widget-1",
  components: {

  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    
  },
  setup() {
   

    return {
    };
  },
});
