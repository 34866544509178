
import { defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getAvgFillLevel, getBInsList } from "@/store/api/KpiServices";
import { string } from "yup/lib/locale";
import moment from "moment";


export default defineComponent({
  name: "widget-10",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    bins: {
      type: Object,
      required: true,
    },
    bins_address: { type: String, required: true },
    date_range: { type: Array as () => Array<any> },
  },
  setup(props) {
    const color = ref(props.chartColor);
    const chartOptions = ref<any>({});
    const series = ref<any[]>([]);
    const binsList = ref(props.bins);
    const bin_address = ref(props.bins_address);
    const date = ref(props.date_range);
    const bin_name = ref<string>("");
    const payload = ref<any>({
      filter: "week",
      bins_in_building: [],
      date_range: [],
    });
    const increase = ref<boolean>(false);
    const avg = ref<any>({
      current_avg: 0,
      previous_avg: 0,
      percent: 0,
    });
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const isBuilding = ref<any>('0');


    onMounted(() => {
      isBuilding.value = localStorage.getItem("building") == "1" ? true : false;
      binsList.value = props.bins;
    });

    const loadChartData = () => {
      if (!isBuilding.value) {
        payload.value.bins_in_building = 'All';
      }
      getAvgFillLevel(payload.value).then((res) => {
        series.value = res["series"];
        avg.value.current_avg = res.current_avg;
        avg.value.previous_avg = res.previous_avg;
        avg.value.percent = res.percent;
        increase.value = res.increase;
        chartOptions.value = {
          chart: {
            fontFamily: "inherit",
            type: "area",
            height: props.chartHeight,
            toolbar: {
              show: true,
            },
          },
          exportEnabled: true,
          plotOptions: {
            area: {
              fillTo: "origin",
            },
          },
          // legend: {
          //   show: true,
          //   style: {
          //     colors: res["colors"],
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            categories: res["catagories"],
          },
          colors: res["colors"],
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            }
          },
          // legend: {
          //   position: 'top',
          //   horizontalAlign: 'left'
          // },
        };
      });
    };

    watch(
      () => props.bins,
      (first) => {
        binsList.value = first;
        payload.value.bins_in_building = Object.values(binsList.value)[0];
        bin_address.value = Object.keys(binsList.value)[0];
      }
    );

    watch(
      () => props.bins_address,
      (first) => {
        bin_address.value = first;
        updateBin(props.bins[bin_address.value]);
      }
    );

    watch(
      () => props.date_range,
      (first) => {
        if (first == null) {
          first = [];
        }
        date.value = first;
        payload.value.date_range = first;
        loadChartData();
      }
    );

    const updateBin = (value) => {
      bin_address.value = value[0].address;
      payload.value.bins_in_building = value;
      loadChartData();
    };

    const updateChart = (val) => {
      payload.value.filter = val;
      payload.value.date_range = [];
      if(val == 'month'){
        payload.value.date_range.push( moment().subtract(1, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }else if(val  == '3m'){
        payload.value.date_range.push( moment().subtract(3, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }else if(val == '6m'){
        payload.value.date_range.push( moment().subtract(6, 'month').toISOString());
        payload.value.date_range.push( moment().toISOString());
      }
      loadChartData();
    };

    return {
      chartOptions,
      series,
      payload,
      updateChart,
      increase,
      avg,
      binsList,
      updateBin,
      bin_name,
      bin_address,
      isBuilding
    };
  },
});
